// src/store/index.ts
import { createStore } from "vuex";

// Define your state's interface
interface State {
  count: number;
}

// Create a new store instance
const store = createStore<State>({
  state: {
    count: 0,
  },
  mutations: {
    increment(state) {
      state.count++;
    },
  },
  actions: {
    increment(context) {
      context.commit("increment");
    },
  },
  getters: {
    count: (state) => state.count,
  },
});

export default store;
