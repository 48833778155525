<!-- src/App.vue -->

<template>
  <v-app>
    <NavBar />
    <v-main>
      <v-container :style="{ minHeight: containerHeight + 'px' }" fluid>
        <v-row justify="center" class="text-center">
          <v-col cols="10">
            <router-view></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { ref } from "vue";
import NavBar from "@/components/NavBar.vue";

const viewportHeight = window.innerHeight;
const containerHeight = ref(Math.max(viewportHeight, 500));
</script>
