// src/main.ts
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { Amplify } from 'aws-amplify';

// Load the configuration file dynamically
import("./amplifyconfiguration.json").then((config) => {
  Amplify.configure(config);
});

createApp(App).use(router).use(store).use(vuetify).mount("#app");