<!-- src/components/NavBar.vue -->
<template>
  <div>
    <!-- App Bar -->
    <v-app-bar color="primary" dark prominent>
      <v-app-bar-nav-icon
        @click="toggleSideNav"
        class="d-flex d-sm-none"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="headline">
        <router-link to="/" class="cursor-pointer d-flex align-center">
          <div style="width: 60px; height: auto">
            <v-img
              alt="Baylifehealth Logo"
              class="shrink mr-2"
              contain
              :src="logoSrc"
            />
          </div>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- Horizontal Nav Items (visible on wide screens) -->
      <div class="d-none d-sm-flex">
        <v-btn
          v-for="item in horizontalNavItems"
          :key="item.title"
          :to="item.link"
          variant="text"
        >
          {{ item.title }}
        </v-btn>
      </div>

      <v-spacer></v-spacer>
    </v-app-bar>

    <!-- Navigation Drawer (visible on mobile) -->
    <v-navigation-drawer v-model="sideNav" location="left" temporary>
      <v-list>
        <v-list-item
          v-for="item in sideNavItems"
          :key="item.title"
          :to="item.link"
          link
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

// Reactive state
const sideNav = ref(false);

// Function to toggle the side navigation state
const toggleSideNav = () => {
  sideNav.value = !sideNav.value;
};

// Main navigation items
const horizontalNavItems = ref([
  { title: "Home", link: "/" },
  { title: "Patients", link: "/patient-login" },
  { title: "Doctors", link: "/doctor-pre-login" },
  { title: "About", link: "/about" },
]);

// sideNavItems
const sideNavItems = ref([
  { title: "Home", link: "/" },
  { title: "Patients", link: "/patient-login" },
  { title: "Doctors", link: "/doctor-pre-login" },
  { title: "About", link: "/about" },
]);

// Logo source URL
const logoSrc = ref(new URL("../assets/logo.png", import.meta.url).href);
</script>

<style>
.logo-size {
  width: 80px; /* Set your desired width */
  height: auto; /* Preserve aspect ratio */
}
</style>
