// src/router/index.ts
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { getCurrentUser } from "@aws-amplify/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"), // Ensure the .vue extension is present for TypeScript
  },
  {
    path: "/patient-login",
    name: "patient-login",
    component: () => import("../views/PatientAuthView.vue"),
  },
  {
    path: "/patient-assessment",
    name: "patient-assessment",
    component: () => import("../views/PatientAssessmentForm.vue"),
    meta: { requiresToken: true },
  },
  {
    path: "/doctor-pre-login",
    name: "doctor-pre-login",
    component: () => import("../views/DoctorPreAuthView.vue"),
  },
  {
    path: "/doctor-login",
    name: "doctor-login",
    component: () => import("../views/DoctorAuthView.vue"),
    meta: { requiresToken: true },
  },
  {
    path: "/doctor-dashboard",
    name: "doctor-dashboard",
    component: () => import("../views/DoctorDashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: '/assessments/:id',
    name: 'assessment-detail',
    component: () => import("../views/AssessmentDetail.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    component: () => import("../views/ErrorView.vue"),
  },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Custom navigation guard for the patient and doctor assessment routes
router.beforeEach(async (to, from, next) => {
  // console.log(`Navigating from ${from.path} to ${to.path}`);
  if (to.matched.some((record) => record.meta.requiresToken)) {
    const userGroup = to.path.includes('patient') ? 'patient' : 'doctor';
    const token = localStorage.getItem(`${userGroup}Token`);
    const expiryTime = localStorage.getItem(`${userGroup}TokenExpiry`);
    const envTokenKey = `VUE_APP_${userGroup.toUpperCase()}_TOKEN`;

    if (token === process.env[envTokenKey] && expiryTime && Date.now() < parseInt(expiryTime)) {
      next();
    } else {
      localStorage.removeItem(`${userGroup}Token`);
      localStorage.removeItem(`${userGroup}TokenExpiry`);
      next({ path: `/` });
    }
  } else if (to.path === "/doctor-pre-login") {
    try {
      const user = await getCurrentUser();
      if (user) {
        next({ path: "/doctor-dashboard" });
      } else {
        next();
      }
    } catch (error) {
      next();
    }
  } else {
    next();
  }
});

export default router;